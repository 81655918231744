.spec-body-content {
  margin-top: 15px;
  margin-left: 25px;
  width:1000px;
}
.spec-body-content-wide {
  margin-top: 15px;
  margin-left: 25px;
  width:1240px;
}
.spec-field-spacer1 {
  height: 20px;
}
.spec-field-spacer3{
  height:25px;
}
.spec-field-spacer2 {
  height: 5px;
}
.spec-fields {
  margin-left: 20px;
}
.damper-spec-fields {
  margin-left: 20px;
  display: inline-flex;
}
.spec-field-install{
  margin-left: 15px;
}
.spec-input-label {
  width: 180px;
  padding: 3px;
}
.spec-input-label-2 {
  display: inline-block;
  width: 200px;
  padding: 3px;
  white-space: nowrap;
  vertical-align: top;
}
.spec-input-label-2B {
  display: inline-block;
  width: 175px;
  padding: 3px;
  white-space: nowrap;
  vertical-align: top;
}
.spec-input-label-2C {
  display: inline-block;
  width: 225px;
  padding: 3px;
  white-space: nowrap;
  vertical-align: top;
}
.spec-input-label-3 {
  display: inline-block;
  width: 230px;
  padding: 3px;
  white-space: nowrap;
}
.spec-input-label-3B {
  display: inline-block;
  width: 280px;
  padding: 3px;
  white-space: nowrap;
}
.spec-input-label-4 {
  display: inline-block;
  width: 520px;
  padding: 3px;
  white-space: nowrap;
}
.spec-input {
  width: 130px;
  padding: 3px;
}
.spec-input-checkbox{
  display: inline-block;
  width: 130px;
  font-size: 14px;  
}
.spec-input-controls2 {
  display: inline-block;
  width: 130px;
  padding: 3px;
  font-size: 14px;
  margin-left:85px; 
}
.spec-input-2 {
  display: inline-block;
  width: 130px;
  padding: 3px;
  font-size: 14px;
}
.controls-inputs {
  margin-left:65px;
}
.spec-input-with-label{
  display: inline-block;
  width: 550px;
  padding: 3px;
  font-size: 14px;
}
.spec-label {
  margin-left:5px;
}
.spec-input-info {
  display: inline-block;
  width: 310px;
  padding: 3px;
  font-size: 14px;
}
.spec-input-3 {
  display: inline-block;
  width: 130px;
  padding: 3px;
  text-align: center;
}
.spec-input-3-Left {
  display: inline-block;
  width: 130px;
  padding: 3px;
  text-align: left;
}
.spec-input-4 {
  display: inline-block;
  width: 250px;
  padding: 3px;
  font-size: 14px;
}
.spec-selectbox-3{
  width: 98px;
}
.spec-selectbox {
  width: 120px;
}
.spec-selectbox-1 {
  width: 140px;
}
.spec-selectbox-1B {
  width: 160px;
}
.spec-selectbox-2 {
  width: 290px;
}
.spec-heading {
  display: inline-block;
  width: 145px;
  font-weight: 600;
}
.spec-heading-fan {
  display: inline-block;
  width: 145px;
  font-weight: 600;
  margin-left:3px;
}
.spec-heading-2 {
  font-weight: 600;
}
.spec-copy-btn {
  display: inline-block;
  width: 175px;
  height: 30px;
  line-height: 30px;
  background-color: #a7fc98;
  border: 4px solid green;
  border-radius: 3px;
  font-size: 14px;
  color: #022108;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.spec-unit-copy {
  display: inline-block;
  margin-left: 10px;
}
.spec-unit-selectbox {
  font-size: 18px;
  width: 150px;
}
.spec-coils-container {
  display: inline-block;
  vertical-align: top;
}
.spec-dampers-container {
  display: inline-block;
  vertical-align: top;
  margin-top:13px;
}
.dampers-container {
  display: inline-block;
}
.spec-margin-top-20 {
  margin-top:30px;
}
.spec-warning {
  padding: 2px;
  width: 750px;
  background-color: #e6a015;
  border: 1px solid #e6a015;
}
.spec-page-division {
  // border: 2px solid #c0c0c0;
  margin-right: 30px;
  // margin-bottom: 15px;
}
.spec-elec-fields {
  margin-top: 15px;
  margin-left: 15px;
}
.spec-elec-fields-new{
  margin-left: 15px;
  margin-top: 5px;
}
.spec-elec-other-fields {
  margin-left: 15px;
}
.spec-elec-field-installation {
  margin-top: 15px;
}
.spec-textbox {
  width: 112px;
  font-size: 12px;
  text-align: right;
}
.spec-content-coming {
  color: #E5242C;
  text-align: center;
  margin-top: 15px;
}
.tooltip {
  position: relative;
  display: inline-block;
  margin-left:5px;
  background-image: url('../images/info.png');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  height:12px;
  width:12px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 270px;
  margin-left:15px;
  background-color: white;
  color: black;
  border:1px solid black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size:11px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.spec-input-spacer {
  height: 20px;
  width: 130px;
}
.floor-drain-checkbox-label {
  display: inline-block;
  margin-left: 10px;
}
.floor-drain-input-checkbox {
  display: inline-block;
  width: 193px;
  font-size: 14px;
}