.connect-inputs-main {
  margin-left: 20px;
  white-space: nowrap;
}
.connect-input-container {
  width: 550px;
  display: inline-block;
  vertical-align: top;
}
.connect-fields-left {
  margin-left: 10px;
}
.connect-input-label {
  display: inline-block;
  width: 180px;
}
.connect-label-indent {
  padding-left: 20px;
  width: 160px !important;
}
.connect-input-label2 {
  display: inline-block;
  width: 160px;
  text-align: center;
}
.connect-input-label3 {
  display: inline-block;
  width: 162px;
  text-align: left;
}
.connect-input {
  display: inline-block;
}
.connect-input2 {
  display: inline-block;
  margin-left: 5px;
  width:195px;
}
.connect-addl-components {
  display: inline-block;
}
.connect-divider {
  margin-top: 10px;
  width: 1095px;
}
.connect-drawing-grid {
  display: table-cell;
  vertical-align: top;
  min-width: 490px;
  height: 400px;
  border: 2px solid #273746;
  background-color: #ffffff;
  border-radius: 6px;
  text-align: center;
}
.connect-next-btn {
  margin-top: 15px;
  margin-right: 10px;
  min-width: 490px;
  text-align: right;
}
.connect-next-img {
  height: 40px;
  cursor: pointer;
}
.connect-drawing-spacer {
  height: 45px;
}
.connect-drawing-label {
  font-size: 22px;
  font-weight: 600;
  margin-top: 15px;
}
.inletOutlet-row-display{
  align-items: baseline;
}
.inletOutlet-row-error {
  float:left
}