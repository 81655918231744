.sizing-main {
  margin-left: 20px;
  white-space: nowrap;
}
.sizing-container {
  width: 500px;
  display: inline-block;
  vertical-align: top;
  z-index: 0;
}
.sizing-container2 {
  display: inline-block;
  vertical-align: top;
}
.sizing-accordions {
  margin-top: 8px;
}
.sizing-spacer {
  height: 10px;
}
.sizing-note {
  width: 100%;
  text-align: center;
}
.sizing-note2 {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.sizing-green {
  font-weight: 600;
  color: green;
}
.sizing-drawing-title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
}
.sizing-drawing-grid {
  display: table-cell;
  vertical-align: middle;
  min-width: 490px;
  height: 345px;
  border: 2px solid #273746;
  background-color: #ffffff;
  border-radius: 6px;
}
