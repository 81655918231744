.contactUsModal {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 510px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    background-color: white;
    color:#696969;
  }
  .submit-btn {
    margin-top:20px;
    padding: 3px;
    text-align: center;
    background-color: #d97a23;
    border: 1px solid black;
    border-radius: 3px;
    width: 70px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    display:inline-block;
    margin-bottom:15px;
  }
  .submit-btn:hover {
    background-color: #1e4d75;
  }
  .title-bar {
      height:55px;
      border-bottom: 1px solid gray;
  }
  .contact-us-label {
      margin-left:8px;
      font-size:30px;
      font-weight: bold;
      display:inline-block;
      margin-top:5px;
  }
  .close-x {
    margin-right: 8px;
    vertical-align: middle;
    float: right;
    font-size:medium;
    margin-top:5px;
    font-weight: bold;
    color:blue;
  }
  .close-x:hover{
      cursor: pointer;
  }
  .send-button-container {
    display: flex;
    justify-content: left;
  }
  .row {
      display:flex;
      margin-top:10px;
  }
  .left-column{
    width:70%;
  }
  /** in case we need content to the right side.
  .right-column{
    width:30%;
  }
  **/
  .form-label {
      font-size:15px;
      line-height: 24px;
      font-weight: 500;
      margin-top:5px;
  }
  .required {
      color:red;
      margin-right:5px;
  }
  .validation-problem {
      color:red;
      font-size:13px;
      font-style: italic;
  }
  .text-input{
    line-height: 30px;
    box-shadow: inset 0 0 5px #000000;
    border-width:0px;
    border:none;
    color:#696969;
    width:325px;
    padding:5px;
  }
  .text-input:hover {
      box-shadow: none;
      border:1px  solid;
      padding:4px;
  }
  .text-input-message{
    box-shadow: inset 0 0 5px #000000;
    border-width:0px;
    border:none;
    color:#696969;
    width:500px;
    height:200px;
    padding:5px;
  }
  .text-input-message:hover {
    box-shadow: none;
    border:1px  solid;
    padding:4px;
  }