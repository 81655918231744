.modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(23, 32, 42, 0.3);
  z-index: 999;
}
.modal-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.modal-popup {
  display: block;
  width: 450px;
  padding: 24px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.modal-title {
  font-size: 20px;
  font-weight: 500;
}
.modal-btn {
  display: inline-block;
  margin-left: 5px;
  height: 34px;
  width: 90px;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.modal-ok-btn {
  color: white;
  background-color: #4b95d6;
  border: 1px solid #2870ad;
}
.modal-ok-btn:hover {
  background-color: #1e4d75;
}
.modal-cancel-btn {
  color: #000000;
  background-color: #d3d3d3;
  border: 1px solid #17202a;
}
.modal-cancel-btn:hover {
  background-color: #a6acaf;
}
.modal-buttons {
  width: 100%;
  text-align: right;
}
.modal-spacer {
  width: 100%;
  height: 300px;
}
.modal-click-target {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-data-content {
  margin-top: 20px;
  margin-bottom: 30px;
}
.fanCurveModal {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 850px;
  padding-left: 10px;
  padding-top: 10px;
  text-align: center;
  background-color: white;
}
.fancurve-content {
  height: 500px;
  width: 830px;
  text-align: center;
  vertical-align: middle;
}
.fancurve-loading {
  background-image: url('../images/loading.svg');
  background-size: 250 250;
  background-repeat: no-repeat;
  background-position: center;
}
.fancurve-error {
  height: 120px;
  width: 450px;
  border: 1px solid gray;
  padding: 15px;
  padding-top: 40px;
  margin: auto;
  margin-top: 200px;
}

.sound-performance-table-head-style {
  border: 3px solid #fff !important;
  text-align: center;
  // font-size: 18px;
  width: 550px;
  margin: auto;
}

.sound-performance-table-head-style tr {
  width: 70%;
  height: 26px;
}

.sound-performance-table-head-style th {
  width: 33%;
  background-color: #5c84aa;
  color: #fff;
  font-weight: bold;
  // font-size: 18px;
  height: 26px;
  white-space: nowrap;
}

.sound-performance-table-head-style td {
  background-color: #d4d5d6;
  font-weight: 400;
  // font-size: 20px;
  height: 26px;
  white-space: nowrap;
}

.sound-plain-text-table {
  width: 550px;
  margin: auto;
}

.sound-plain-text-table-head-style {
  vertical-align: top;
  text-align: left;
  font-size: 12px;
  width: 100%;
}

.sound-plain-text-table-head-style td {
  vertical-align: top;
  text-align: left;
  font-size: 12px;
}
