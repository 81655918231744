$menu-primary: #5a678af3;
$menu-secondary: #e7eefff3;

.app-topright-menus {
  display:inline-block;
  position:relative;
  top: 5px;
}
.app-topright-pricing{
  display:inline-block;
}
// .app-topright-menus {
//   display: inline-block;
//   vertical-align: top;
//   height: 30px;
//   margin-top: 3px;
// }
.top-right-pricing {
  float: right;
  margin-right: 150px;
  white-space: nowrap;
}
.top-right-modal {
  position: absolute;
  top: 45px;
  right: 100px;
  border: 1px solid rgb(65, 65, 65);
  border-radius: 0 0 8px 8px;
  padding: 5px;
  background-color: #f9fbff;
}
.topright-error-item {
  color: #9c3333;
  white-space: nowrap;
  max-width: 875px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.topright-error-heading {
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.topright-error-close {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}
.top-pricing {
  font-size: 15px;
  font-weight: 600;
  margin-top: 3px;
  color: #1e6f1b;
}
.pricing-disabled {
  color: #999999 !important;
}
.pricing-error {
  color: #9c3333 !important;
  text-decoration: underline;
  cursor: pointer;
}
.top-pricing-btn {
  margin-left: 20px;
  padding: 3px;
  text-align: center;
  color: #ffffff;
  height: 15px;
  width: 100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  background-color: #1e6f1b;
  border: 1px solid #1e6f1b;
  border-radius: 3px;
  cursor: pointer;
}
.pricing-btn-disabled {
  color: #dedede;
  background-color: #999999 !important;
  border: 1px solid #999999 !important;
  cursor: default;
}
.pricing-btn-error {
  color: #ffffff;
  background-color: #9c3333 !important;
  border: 1px solid #9c3333 !important;
  cursor: pointer;
}
.app-hvac {
  height: 25px;
  width: 25px;
  //   opacity: 0.3;
  margin-right: 8px;
  position: absolute;
  top: 8px;
  left: 130px;
  background-image: url('../images/hvac.png');
  background-size: 40px 28px;
}
.top-right-menu {
  width: 110px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #5e5e5e;
  cursor: pointer;
}
.top-right-menu2 {
  width: 80px;
}
.app-box :hover {
  //   background-color: #5b7f95;
  //   color: #ffffff;
}
.top-menu-text {
  color: #2d5f7e;
}
.app-box-divider {
  display: inline-block;
  height: 28px;
  vertical-align: middle;
  border-left: 1px solid #bfbfbf;
}
.menu-item1 {
  position: relative;
  //   border-left: 1px solid #bfbfbf;
}
.menu-item2 {
  padding-left: 18px;
  //   border-right: 1px solid #bfbfbf;
  //   border-left: 1px solid #bfbfbf;
}
.app-options-menu1 {
  position: absolute;
  top: 40px;
  right: 0;
  height: 410px;
  width: 220px;
  padding: 5px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  border-radius: 3px;
  z-index: 999;
  box-shadow: 2px 2px 3px 3px rgba(22, 22, 22, 0.1);
}
.app-options-menu {
  position: relative;
  float: left;
  width: 120px;
  border-right: 1px solid #9d9d9d;
  border-left: 1px solid #9d9d9d;
  padding-left: 10px;
  line-height: 40px;
  padding-right: 10px;
  text-align: left;
  color: #6c6c6c;
  cursor: pointer;
}
.menu-icon {
  display: inline-block;
  height: 25px;
  width: 25px;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  padding-left: 20px;
}
.menu-icon-desc {
  font-size: 13px;
  line-height: 8px;
  text-align: center;
  margin: auto;
  width: 100px;
  height:12px;
}
.menu-icon-desc-2 {
  font-size: 13px;
  line-height: 8px;
  margin: auto;
}
.save-icon-loading {
  background-image: url('../images/loading.svg');
}



/*========= dropdown menu items ========== */
.menu-dropdown {
  display: block;
  padding-left: 30px;
  margin-top: 5px;
  color: #5e5e5e !important;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: 9px 8px;
}
.menu-dd-disabled {
  opacity: 0.4;
}
.menu-dd1 {
  background-image: url(../images/home.png);
}
.menu-dd1:hover {
  color: #ffffff !important;
  background-image: url(../images/home-reversed.png);
}
.menu-dd2 {
  background-image: url(../images/save.png);
}
.menu-dd2:hover {
  cursor: pointer;
  color: #ffffff !important;
  background-image: url(../images/save-reversed.png);
}
.menu-dd2-disabled {
  cursor:none;
  pointer-events: none;
  color: #ffffff !important;
  background-image: url(../images/save.png);
  opacity: .6;
}
.menu-dd3 {
  background-image: url(../images/document.png);
}
.menu-dd3:hover {
  color: #ffffff !important;
  background-image: url(../images/document-reversed.png);
}
.menu-dd4 {
  background-image: url(../images/print.png);
}
.menu-dd4:hover {
  cursor: pointer;
  color: #ffffff !important;
  background-image: url(../images/print-reversed.png);
}
.menu-dd4-disabled {
  cursor:none;
  pointer-events: none;
  color: #ffffff !important;
  background-image: url(../images/print.png);
  opacity: .6;
}
.menu-dd4-checkmark {
  background-image: url(../images/print-checkmark.png);
}
.menu-dd5 {
  background-image: url(../images/pricing.png);
}
.menu-dd5:hover {
  cursor: pointer;
  color: #ffffff !important;
  background-image: url(../images/pricing-reversed.png);
}
.menu-dd5-disabled {
  cursor: none;
  pointer-events: none;
  color: #ffffff !important;
  background-image: url(../images/pricing.png);
  opacity: 0.6;
}
.menu-dd7 {
  background-image: url(../images/logout1.png);
}
.menu-dd7:hover {
  cursor: pointer;
  color: #ffffff !important;
  background-image: url(../images/logout1-reversed.png);
}
.menu-dd8 {
  background-image: url(../images/help.png);
}
.menu-dd8:hover {
  cursor: pointer;
  color: #ffffff !important;
  background-image: url(../images/help-white.png);
}
.menu-dropdown2 {
  display: block;
  padding-left: 35px;
  margin-top: 5px;
  color: #5e5e5e !important;
  background-size: 27px 22px;
  background-repeat: no-repeat;
  background-position: 9px 10px;
}
.menu-dd2-1 {
  background-image: url(../images/hvac.png);
}
.menu-dd2-1:hover {
  background-color: #5b7f95;
  color: #ffffff !important;
  background-image: url(../images/hvac-reversed.png);
}
/* =========================================================== */

.menu-img2 {
  display: inline-block;
  margin-left: 10px;
  height: 25px;
  width: 30px;
  vertical-align: middle;
  cursor: pointer;
}
.menu-label {
  display: inline-block;
  margin-left: 10px;
  height: 25px;
  line-height: 25px;
  //   color: #5e5e5e;
  vertical-align: middle;
}

.menu-home {
  background-image: url(../images/home.png);
}
.menu-home:hover {
  background-image: url(../images/home-reversed.png);
}

.menu-arrow-down {
  vertical-align: top;
  margin-top: 14px;
  border: solid #2d5f7e;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.save-btn {
  width: 75px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  // background-color: #5b7f95;
  background-color: $menu-primary;
  border-radius: 5px;
  border: 1px solid #787878;
  cursor: pointer;
}
