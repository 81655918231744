/** ----------- Main Accordion Styles ------------------------ **/
.accordion-btn {
  position: relative;
  // background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 8px;
  width: 540px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  font-size: 14px;
  transition: 0.2s;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,cecece+100 */
  background: #f2f2f2; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#e6e6e6',GradientType=0 ); /* IE6-9 */
  border: 1px solid #c1c1c1;
}
.acc-header-locked {
  border-bottom: 3px solid rgb(224, 191, 2);
}
.active-accordian,
.accordion-btn:hover {
  background-color: #ccc;
}
.acc-panel-450 {
  width: 450px;
}
.acc-panel-540 {
  width: 540px;
}
.acc-panel-1000 {
  width: 100%;
}
.acc-panel-active {
  display: block;
}
.acc-panel-inactive {
  display: none;
}

.accordion-details-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  color: white;
  font-weight: 400;
  text-align: center;
  background-color: #43a7c8;
  border-radius: 5px;
  border: 1px solid #3089a7;
  cursor: pointer;
}
.accordion-details-btn:hover {
  background-color: #5b7f95;
}
.accordion-btn2 {
  right: 120px;
}
.accordion-arrow {
  content: '';
  border: solid #575757;
  border-width: 0 3px 3px 0;
  padding: 3px;
  position: absolute;
  right: 25px;
  top: 40%;
}
.acc-down-arrow {
  -ms-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
}
.acc-up-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  right: 23px;
}
.accordion-content {
  position: relative;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 5px;
  background-color: #ffffff;
}
.acc-content-locked {
  background-color: palegoldenrod;
}
.acc-indent {
  padding-left: 25px;
}
.acc-btn-450 {
  width: 450px;
}
.acc-btn-1000 {
  width: 100%;
}
/** ----------- Accordion Inputs & Labels ------------------------ **/
.accordion-title {
  height: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
.accordion-row {
  padding-top: 7px;
  white-space: nowrap;
}
.accordion-label {
  display: inline-block;
  width: 140px;
}
.accordion-label-80 {
  display: inline-block;
  width: 80px;
}
.accordion-label-90 {
  display: inline-block;
  text-align: right;
  width: 90px;
}
.accordion-label-90-left {
  display: inline-block;
  width: 90px;
}
.accordion-label-95 {
  display: inline-block;
  width: 95px;
}
.accordion-label-60 {
  display: inline-block;
  text-align: right;
  width: 60px;
}
.accordion-label-150 {
  display: inline-block;
  width: 150px;
}
.accordion-label-50 {
  display: inline-block;
  width: 50px;
}
.accordion-label2 {
  display: inline-block;
  width: 130px;
  padding-top: 5px;
}
.accordion-label-hw {
  display: inline-block;
  width: 160px;
  padding-top: 5px;
}
.accordion-label3 {
  display: inline-block;
  width: 150px;
  padding-top: 5px;
}
.accordion-label-centered {
  display: inline-block;
  width: 100px;
  text-align: center;
  padding-top: 5px;
}
.accordion-input {
  display: inline-block;
  position: relative;
  width: 165px;
}
.accordion-input-25 {
  display: inline-block;
  position: relative;
  width: 25px;
}
.accordion-input-100 {
  display: inline-block;
  position: relative;
  width: 100px;
}
.accordion-input-175 {
  display: inline-block;
  position: relative;
  width: 175px;
}
.accordion-input-230 {
  display: inline-block;
  position: relative;
  width: 255px;
}
.accordion-select {
  width: 98px;
  font-size: 14px;
}
.accordion-textbox {
  width: 90px;
  font-size: 12px;
  text-align: right;
}
.accordion-label-numeric {
  display: inline-block;
  width: 60px;
  text-align: right;
}
.accordion-label-numeric1 {
  display: inline-block;
  width: 63px;
  text-align: right;
}
.accordion-label-numeric2 {
  display: inline-block;
  width: 97px;
  text-align: right;
}
.acc-label-110 {
  width: 110px;
}
.acc-label-170 {
  width: 185px;
}
.acc-label-ellip {
  display: inline-block;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.acc-label-ellip:hover {
  overflow: visible;
}
.acc-checkbox {
  height: 18px;
  width: 18px;
  vertical-align: bottom;
}
.acc-locking {
  padding-top: 5px;
}
.acc-lock-text {
  font-size: 16px;
  line-height: 23px;
}
/** ----------- Heat Wheel ------------------------ **/
.hw-spacer {
  height: 20px;
}
/** ----------- Fans ------------------------ **/
.acc-fan-spacer {
  height: 20px;
}
/** ----------- Coils ------------------------ **/
.acc-coil-spacer {
  height: 10px;
}
/** ----------- Refrigeration -------------------- **/
.refrig-dxcoil {
  position: relative;
}
.refrig-divider {
  width: 95%;
  padding: 10px;
  border-bottom: 1px dashed darkgray;
}
.refrig-spacer {
  height: 5px;
}

/** ----------- Accordion Data Tables -------------------- **/
.acc-table {
  display: table;
  margin-top: 15px;
}
.acc-td1 {
  display: table-cell;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: bottom;
}
.acc-td2 {
  display: table-cell;
  width: 10px;
}
.acc-td-optional {
  display: table-cell;
  padding: 5px;
  text-align: center;
  border: 2px solid darkgray;
  border-radius: 3px;
  cursor: pointer;
}
.acc-td-recommended {
  display: table-cell;
  padding: 5px;
  text-align: center;
  color: #000000;
  border: 2px solid darkgray;
  border-radius: 3px;
  cursor: pointer;
}
.acc-td-selected {
  border: 2px solid #1e7b30;
  background-color: #e3ffe8;
}
.acc-data1 {
  padding-bottom: 8px;
}
.acc-data2 {
  padding-bottom: 3px;
  white-space: nowrap;
}
.acc-lock-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-image: url('../images/locked.svg');
  background-size: 20px 20px;
}
.acc-errors {
  width: 95%;
}
.acc-header-error {
  // background: none !important;
  // background-color: #dfaeae !important;
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  background-image: url('../images/error.png');
  background-size: 20px 20px;
}
/** ----------- Accordion Additional Fields -------------------- **/
.acc-show-more {
  width: 97%;
  border-bottom: 1px dashed #dedede;
  text-align: center;
  height: 10px;
  margin-top: 10px;
}
.acc-show-txt {
  color: #006aac;
  background: #f5f5f5;
  padding: 0 5px;
  cursor: pointer;
}
.acc-spacer {
  height: 16px;
}
.acc-addl-fields {
  height: 300px;
  display: none;
}
.acc-fieldset {
  display: inline-block;
}

/** ----------- New Optimize Design layout-------------------- **/
.optimize-main-heading{
  display:inline-block;
  font-size:16px;
  padding:25px;
  width:480px;
  background-color:#ffffff;
  border:1px solid #dedede;
  vertical-align: top;
  margin-top:40px;
  margin-left:35px;
}
.optimize-design-numbers{
  display:inline-block;
  width:300px;
  vertical-align: top;
  margin-top:10px;
}
.optimize-left{
  display:inline-block;
}
.optimize-left-heading{
  font-size:14px;
  font-weight:600;
}
.optimize-right{
  display:inline-block;
  margin-left:30px;
  vertical-align: top;
}

.optimize-heading{
  font-size:16px;
  font-weight:600;
  margin-top:20px;
  margin-left:25px;
}
.optimize-heading2{
  font-size:16px;
  font-weight:600;
  margin-left:45px;
}
.optimize-heading-disabled{
  font-size:16px;
  font-weight:600;
  margin-top:20px;
  margin-left:25px;
  color: #c4c4c4 !important;
}
.optimize-fields{
  font-size:15px;
  margin-top:15px;
  margin-left:25px;
}
.optimize-fields2{
  font-size:15px;
  margin-top:15px;
  margin-left:85px;
}
.optimize-label1{
  display:inline-block;
  width:200px;
  line-height:26px;
}
.optimize-label2{
  display:inline-block;
  line-height:26px;
}
.optimize-label3{
  display:inline-block;
  line-height:26px;
}
.optimize-label-disabled {
  display: inline-block;
  color: #c4c4c4 !important;
}
.optimize-reset-label{
  color:#6aa6dff3;
  text-decoration:underline;
  cursor:pointer;
}
.component-btn {
  position: relative;
  // background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 8px;
  width: 210px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  font-size: 14px;
  transition: 0.2s;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,cecece+100 */
  background: #f2f2f2; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #f2f2f2 0%,
    #e6e6e6 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#e6e6e6',GradientType=0 ); /* IE6-9 */
  border: 1px solid #c1c1c1;
}
.component-btn-selected{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#deeaf4+0,c4def2+100 */
background: rgb(222,234,244); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(222,234,244,1) 0%, rgba(196,222,242,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(222,234,244,1) 0%,rgba(196,222,242,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(222,234,244,1) 0%,rgba(196,222,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#deeaf4', endColorstr='#c4def2',GradientType=0 ); /* IE6-9 */

}
.component-btn:hover {
  background-color:blue;
}
.optimize-components-main{
  margin-top:20px;
  margin-left:45px;
}
.optimize-fieldset{
  width:600px;
  border-radius:5px;
  margin:0;
  padding:5px;
  background-color:#ffffff;
  border-color:rgba(90,103,138,.952941);
}
.optimize-legend{
  font-size:16px;
  font-weight:600;
  color:rgba(90,103,138,.952941);
  padding-left:5px;
  padding-right:5px;
}
.optimize-recalc{
  height:35px;
  width:900px;
}
.optimize-update-btn {
  margin-left:700px;
  width: 185px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  // background-color: #5a678af3;
  background-color:#428a41;
  border-radius: 5px;
  // border: 1px solid #787878;
  border: 1px solid #3b7e3b;
  cursor: pointer;
}
.optimize-update-btn-disabled {
  background-color: #c4c4c4;
  cursor: default;
}
.optimize-bottom-spacer{
  height:200px;
  width: 100%;
}
.opt-prereq{
  margin-top:80px;
  margin-left:120px;
}
.opt-prereq-title{
  font-size:24px;
  font-weight:500;
}
.opt-prereq-heading {
  margin-top: 25px;
  display:grid;
  grid-template-columns: 250px 300px;
  align-items:center;
  margin-bottom:15px;
  font-weight: bold;
}
.opt-prereq-msg{
  margin-top:20px;
}
.opt-spacer{
  height:25px;
}
.opt-spacer-15{
  height:15px;
}
.opt-spacer-15-line{
  height:15px;
  border-top:1px solid #dedede;
}
.opt-err1{
  display:inline-block;
  min-width: 250px;
}
.opt-err2{
  display:inline-block;
}

/** ----------- New Optimize Design layout-------------------- **/
.opt-design-data{
  // margin-top:5px;
  // margin-left:5px;
  width:1120px;
  // border:1px solid #dedede;
  // border-radius:5px;
  background-color: #ffffff;
  padding:10px;
}
.opt-info-title{
  margin-top:15px;
  margin-left:45px;
}
.opt-col-heading60{
  display:inline-block;
  width:60px;
  text-align: center;
}
.opt-col-heading125{
  display:inline-block;
  width:125px;
  text-align: center;
}
.opt-col-heading180{
  display:inline-block;
  width:180px;
  text-align: center;
}
.opt-col-category{
  display:inline-block;
  font-size:16px;
  font-weight:600;
  // margin-left:5px;
  width:220px; 
}
.opt-disabled{
  color: #c4c4c4 !important;
}
.optimize-reset-label2{
  display:inline-block;
  margin-top:15px;
  margin-left:45px;
  color:#6aa6dff3;
  text-decoration:underline;
  cursor:pointer;
}
.optimize-main-heading2{
  display:inline-block;
  font-size:16px;
  padding:15px;
  width:600px;
  background-color:#ffffff;
  border:1px solid #dedede;
  vertical-align: top;
  // margin-top:40px;
  margin-left:35px;
}
.opt-info-panel{
  position:relative;
  width:1200px;
  margin-left:15px;
  margin-top:20px;
}
.optimize-update-btn2 {
  // margin-left:700px;
  width: 185px;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  // background-color: #5a678af3;
  background-color:#428a41;
  border-radius: 5px;
  // border: 1px solid #787878;
  border: 1px solid #3b7e3b;
  cursor: pointer;
}
.accordion-label-154 {
  display: inline-block;
  width: 154px;
}
.accordion-label-162 {
  display: inline-block;
  width: 162px;
}
.accordion-select-98 {
  width: 98px;
  font-size: 14px;
}
.accordion-label-inletoutlet {
  display: inline-block;
  width: 156px;
}
.accordion-label-155 {
  display: inline-block;
  width: 155px;
}
.accordion-label-161 {
  display: inline-block;
  width: 161px;
}
.acc-heading{
  font-weight:600;
  border-bottom:1px solid #aaaaaa;
  margin-bottom:10px;
  padding-bottom:5px;
}
.accordion-input-dx {
  display: inline-block;
  position: relative;
  width: 130px;
}
.accordion-label-dx {
  display: inline-block;
  width: 100px;
}
.accordion-label-numeric-dx {
  display: inline-block;
  width: 30px;
  text-align: right;
  margin-right: 50px;
}
.accordion-label-cooling {
  display: inline-block;
  width:50px;
  margin-left: 161px;
  font-weight: bold;
}
.accordion-label-heating {
  display: inline-block;
  width:50px;
  margin-left:12px;
  font-weight: bold;
}
.accorion-label-col3 {
  display: inline-block;
  width:140px;
  margin-left: 20px;
}
.optimize-fieldset-wshp{
  width:800px;
  border-radius:5px;
  margin:0;
  padding:5px;
  background-color:#ffffff;
  border-color:rgba(90,103,138,.952941);
}
.accordion-label-wshp {
  width:120px;
  margin-left: 30px;
  float:right;
  margin-right:106px;
}
.accordion-label-numeric-wshp {
  width: 60px;
  text-align: right;
  float:right;
  margin-right:-209px;
}