$proj-primary: #293f7cf3;
.download-btn {
  padding: 3px;
  text-align: center;
  background-color: $proj-primary;
  border: 1px solid $proj-primary;
  border-radius: 3px;
  width: 50px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 200px;
  font-size: 14px;
  line-height: 15px;
  margin-left: 5px;
}
.div-clear {
  clear: both;
  height: 10px;
}

.buttonDisabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.versionTemplate {
  padding: 3px;
  margin-left: 32px;
}
.app-outputs-heading {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}
.app-outputs-subheading {
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 8px;
  padding-top: 20px;
  font-size: 16px;
  text-decoration: underline;
  width: 190px;
}
.app-output-container {
  display: table;
  margin-left: 22px;
  width:90%
}
.app-outputs-left {
  padding-top: 30px;
  display: table-cell;
  width: 20%;
  min-width:305px;
}
.app-outputs-right {
  padding-top: 30px;
  display: table-cell;
  width:80%
}
.app-outputs-unitselection {
  padding-top: 30px;
  padding-left: 40px;
  display: table-cell;
  width: 350px;
}
.app-outputs-docs {
  min-width: 210px;
  max-width: 210px;
}
.app-outputs-units {
  min-width: 235px;
  max-width: 210px;
}
.app-outputs-checkbox {
  padding-top: 5px;
  font-size: 14px;
  margin-left: 20px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.app-outputs-generate-btn {
  margin-left: 20px;
  width: 192px;
  margin-top: 50px;
  margin-right: auto;
  height: 28px;
  line-height: 29px;
  background-color: #a7fc98;
  border: 4px solid green;
  border-radius: 3px;
  font-size: 14px;
  color: #022108;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.app-outputs-generate-disable {
  background-color: lightgray !important;
  pointer-events: none;
  border: 4px solid grey !important;
  opacity: 0.5;
}
.linkAsButton {
  color: blue;
  font-size: 14px;
  white-space:nowrap;
}
.linkAsButtonUnit {
  color: blue;
  font-size: 14px;
  margin-left: 15px;
  float: right;
  margin-right: 50px;
}
.app-outputs-spacer {
  height: 30px;
}
.app-outputs-spacer2 {
  height: 20px;
}
.outputs-table1 {
  display: table;
  table-layout: fixed;
  width: 99%;
  border-collapse: collapse;
  background-color: #ffffff;
  white-space: normal;
  font-size:14px;
}
.outputs-table-download-column{
  width: 10% 
}
.outputs-table-doc-type-column{
  width:10%;
  white-space:nowrap;
}
.outputs-table-column-overflow{
  overflow: hidden;
  text-overflow: ellipsis;
}
.outputs-pricing-notes{
  color:#ff0000;
}
/* Tooltip container */
.outputs-tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black;  If you want dots under the hoverable text */
}

/* Tooltip text */
.outputs-tooltip .outputs-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1001;
}

/* Show the tooltip text when you mouse over the tooltip container */
/*.outputs-tooltip:hover .outputs-tooltiptext {
  border: 1px solid red; 
  visibility: visible;
  background-color: lightgray;
}*/
.outputs-tooltip:hover,
.outputs-tooltip.hovered
.outputs-tooltiptext {
  /* Your hover styles here */
  visibility: visible;
  /*background-color: lightgray;*/
}
.outputs-download-td {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
