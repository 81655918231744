html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Helvetica Neue', 'sans-serif', 'Roboto';
  font-weight: 400;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: #2f2f2f;
  background-color: #f5f5f5;
}
a {
  text-decoration: none;
  color: inherit;
}
#root {
  height: 100%;
  width: 100%;
}

.app-box-shadow {
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.app-clear {
  clear: both;
}
.input-numeric {
  width: 155px;
  text-align: right;
  margin-top: 8px;
  z-index: 0;
}
.input-numeric-40 {
  width: 40px;
  text-align: right;
  margin-left: 10px;
  z-index: 0;
}
.input-numeric-50 {
  width: 50px;
  text-align: right;
  margin-left: 10px;
  z-index: 0;
}
.input-numeric-100 {
  width: 100px;
  text-align: right;
  margin-left: 10px;
  z-index: 0;
}
.input-error {
  border: 2px solid red;
}
.input-select {
  width: 162px;
  margin-top: 8px;
}
.input-heading {
  margin-top: 20px;
  font-weight: bold;
}
.input-heading-no-margin {
  font-weight: bold;
}
.input-fields {
  margin-left: 20px;
}
.input-textbox {
  display: inline-block;
  position: relative;
}
.input-disabled {
  background-color: rgb(190, 190, 190);
  color: gray;
}
.validation-error {
  position: absolute;
  top: 30px;
  width: 152px;
  font-size: 13px;
  padding: 3px;
  z-index: 999;
  color: #380202;
  background-color: #e3a8a8;
  border: 1px solid #b52626;
  border-radius: 2px;
}
.validation-error200 {
  margin-left: 200px;
  font-size: 11px;
  width: 220px;
  white-space: normal;
  color: red;
}
.validation-error220 {
  font-size: 11px;
  width: 220px;
  white-space: normal;
  color: red;
}
.validation-error215 {
  margin-left: 215px;
  width: 220px;
  white-space: normal;
  font-size: 11px;
  color: red;
}
.validation-error250 {
  margin-left: 250px;
  font-size: 11px;
  width: 220px;
  white-space: normal;
  color: red;
}
.validation-error0 {
  // position: absolute;  //commenting this out because it messes up positioning on optimize design accordions
  font-size: 11px;
  color: red;
}
.validation-error1 {
  font-size: 11px;
  color: red;
}
.validation-error-float {
  font-size: 11px;
  color: red;
  float:left;
  text-wrap:auto;
}
.app-display-none {
  display: none;
}
.validation-err-right {
  white-space: normal;
  font-size: 11px;
  color: red;
  text-align: right;
  margin-right: 35px;
}
.validation-err-right2 {
  white-space: normal;
  font-size: 11px;
  color: red;
  text-align: right;
}
.validation-err-right-inlet-outlet {
  white-space: normal;
  font-size: 11px;
  color: red;
  margin-right: 35px;
  word-wrap: break-word;
}
.validation-err-inletOutlet-location{
  white-space: normal;
  font-size: 11px;
  color: red;
  width: 170px;
}
.spare-filter-select {
  width: 110px;
  margin-top: 8px;
}

.validation-err-bms {
    white-space: normal;
    font-size: 11px;
    color: red;
    text-align: right;
    margin-left: 10px;
    display: inline-block;
}