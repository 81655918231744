.config-inputs-main {
  margin-left: 20px;
  white-space: nowrap;
}
.config-input-container {
  width: 420px;
  display: inline-block;
  vertical-align: top;
  z-index: 0;
}
.config-input-container2 {
  display: inline-block;
  vertical-align: top;
}
.config-input-label {
  display: inline-block;
  width: 200px;
}
.config-short-label {
  display: inline-block;
  width: 180px;
}
.config-long-label {
  display: inline-block;
  width: 250px;
  margin-left: 20px;
}
.config-drawing-grid {
  display: table-cell;
  vertical-align: middle;
  min-width: 490px;
  height: 345px;
  border: 2px solid #273746;
  background-color: #ffffff;
  border-radius: 6px;
}
.config-next-btn {
  margin-top: 15px;
  text-align: right;
}
.config-next-img {
  height: 40px;
  cursor: pointer;
}
.config-drawing-spacer {
  height: 45px;
}
.config-input-fields {
  display: inline-block;
  margin-left: 25px;
}
.config-input-fields2 {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}
.config-unit-type {
  display: inline-block;
  width: 160px;
  padding: 2px;
  font-weight: 500;
}
.config-input-fields-HeatingCooling {
  display:inline-block;
  vertical-align: top;
  margin-top:20px;
}
