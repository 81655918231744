.pool-inputs-main {
    margin-left: 20px;
    white-space: nowrap;
 }
 .pools-input-container {
    vertical-align: top;
    z-index: 0;
 }
 .pools-add-btn:hover{
    background-color: #506294f3; 
 }
 .pools-add-btn{
    width: 90px;
    height: 25px;
    line-height: 25px;
    background-color: #293f7cf3;
    border: 2px solid #293f7cf3;
    border-radius: 3px;
    font-size: 14px;
    color: white;
    text-align: center;
    font-weight: 500;
    cursor: pointer;  
    display: inline-block;
 }
 .pools-add-button-disable {
    background-color: lightgray !important;
    pointer-events: none;
    border: 1px solid grey !important;
    opacity: 0.5;
  }
 .pool-label{
    white-space: normal;
    text-align: center;
    overflow-wrap: break-word;
 }
 .pool-info-rows-container {
    display: inline-block;
 }
.pool-wrapper {
    margin-top:26px;
    display:grid;
    grid-template-columns: 145px 175px 125px 125px 175px 125px 100px;
    margin-left:25px;
    height:11px;
    align-content: center;
}
.pool-heading-wrapper {
    margin-top: 25px;
    display:grid;
    grid-template-columns: 125px 175px 125px 125px 175px 125px 100px;
    align-items:center;
    margin-top:auto;
    margin-bottom:15px;
    margin-left:25px;
}
.pool-bold {
    font-weight: bold;
}
.pool-bottom-align {
    margin-top:20px;
}
.pool-input-heading {
    margin-top: 20px;
    font-weight: bold;
    margin-bottom:20px;
}
.pool-input-heading2 {
    margin-top: 5px;
    font-weight: bold;
    margin-bottom:20px;
}
.pool-spacer {
    height: 25px;
}
.pool-spacer-small {
  height:10px;
}
.pool-input-fields {
    display: inline-block;
    margin-left: 25px;
    line-height: 28px;
}
.pool-summer-space-grid {
    display:grid;
    grid-template-columns: 45px 90px 45px 90px 45px 90px 65px 90px;
}
.pool-winter-space-grid {
    display:grid;
    grid-template-columns: 45px 90px 45px 90px;
}
.pool-input-label {
    display: inline-block;
    width: 218px;
  }
  .pool-input-grid{
    display:grid;
    grid-template-columns: 400px 200px;
  }
  .pool-project-info-grid {
    display:grid;
    grid-template-columns: 250px 175px;
  }
  .pool-input-numeric-50 {
    width: 50px;
    text-align: right;
    z-index: 0;
  }
  .pool-input-numeric-110 {
    width: 110px;
    z-index: 0; 
  }   
  .pool-select {
    width: 80px;
  }
  .pool-select-large {
    width: 140px;
  }
  .pool-section-grid {
      display: grid;
      grid-template-columns: 625px 700px;
      margin-top: 15px;
  }
  .pool-input-numeric {
    width: 155px;
    text-align: right;
    z-index: 0;
  }
  .pool-first {
      margin-left:18px;
  }
  .validation-pool-space-loads {
    white-space: normal;
    font-size: 11px;
    color: red;
    line-height: 12px;
  }
.validation-pool {
    white-space: normal;
    font-size: 11px;
    color: red;
    line-height: 12px;
 }
.pool-activity-factor {
    margin-left:15px;
}
.pool-calc-value {
  display: inline-block;
  width: 275px;
  margin-left:25px;
}
.pool-calc-btn {
  margin-left:25px;
  width: 185px;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  background-color:#428a41;
  border-radius: 5px;
  border: 1px solid #3b7e3b;
  cursor: pointer;
}
.pool-prereq{
  margin-top:50px;
  margin-left:25px;
}
.pool-prereq-msg{
  margin-bottom:15px;
  color:red;
}
.pool-err{
  display:inline-block;
  color:red;
}
.pool-tooltip-info {
  display: inline-block;
  position: relative;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAF96VFh0UmF3IHByb2ZpbGUgdHlwZSBBUFAxAAAImeNKT81LLcpMVigoyk/LzEnlUgADYxMuE0sTS6NEAwMDCwMIMDQwMDYEkkZAtjlUKNEABZgamFmaGZsZmgMxiM8FAEi2FMk61EMyAAAER0lEQVQ4jXWVS2xVRRjHfzNnzrm3p+/e0gK9iJTIo1igBF+VCrKwutGFIRITtSagJsaFm8JGGqIk0AU7XSALNhpMdKEGkxIlSrWG2FBCC4hKeeTSXuj7vs8958y4uK2hAt9iMjOZ7/993z///EfwgGjtmYo7yusMdNgx4YVtY9o0lwuI22K0wraGNPQHgegbPhhP/D9XLDoZI7Z8Mr5nxgu6ruf99hoXtscUsYjECw1X5wIGpwNQks1lagChTlw40HQcIcx9gC09c3Wundo3mAm6X4wJXm2roGVFGTVVDm7EIggNqaxPcsrjp5EURy/l2RCxsG27txiKI5cPrpgGUAuduYcS+wZTfve+jVFefy5GfbWNFILx6QLXx7O4EYvmpS7L6yK0PFrB04/N8cGpSaqLptt1HDBmP0IYC+AJ9e7ewYx/uHtTlD0vNFDjKgpFjWVJTp27w1ufXeP83SydLTWUORZaw+rlLm0xxckraSrg2cb+3O27Px89L1t7puLjhaDrtSaLN3fU4zoSz9dIWaKisUrxzBqHHcsdHFW6NBgKXshT66vp3VnLcNbHNn5Xa08irhzldSa8sH3Xk7XEKm3yXoiUJWoDbWjf1MDGtTEiShC1JYE2SCEwBoq+ZltrLbtH0pweD9pXuqpT3fHCjpfrJWviLn6gEaIEJoXg7FCS5EyRqC1wHIttG5dQUWYThgYhQGtDeVTRsbackzdnWeUGHSoR6rZdS2xqXAttYB4PIeH738f5ajANNYpVDVG+WRejyoUQAwgMIAVsiLtgpjHGtCmMaa6rtPhPSAuS1PBKRxMmMsFvt7Ksq7b/637xQ7BtiSqzCDTN8mEaD7Vm55ZGnm+p5XYqXMh9SAis+Z1EiNHJuWB+gPuri/kEISRKikVlxfziFUO8fIiSjMqVthz6e9JnJhMgJZh7cDOeJlfUoCTaGFKFAD80i0bX2nApkQMhEUIMyUbb6v9hQjNyI4ttScw8omVJvjh9g+PnJtlcp5hJBxz4epRrYxkcJQm1wbIkqVzAj5czLItaaFS/zAeRvtVRNfDlH2nGZ4pEbInWJVncTBa4nPC5WpScyxjOXMmRL4QgSuNaluCXizN8mwxZFlEDgRZ9AmDLx2N7z2e9Yx+uc3jvpQYqohZ+YLh1N8dU2kdJgTEGKQXNS8updBWWJfh1ZJbd303QGlFo6bwz3BP/vESGMWLrocThwbTf/f4ahze2x2iqj+AogTagdUmflgRtIJ0LOTs8y/4zMyyxJLZt9178aMV+hDAltxHC5HrmjmytzPDpP353/1iStzeXs/4Rl4Yam9pyC883JGeLJKeKnLmU4dhfeR4vt5HK6g1CcWTBEx9osMUw7BrJBu1VUdhWJWmMCvKB4c+M5kIqpFZJmqNqoIg8Mfwwg703Wnum4mXK6/S07pgNddvt0DRXCViqxKhryaEQ2R8Ekb7hg7H7voB/Acrh3SRNM8ABAAAAAElFTkSuQmCC);
  background-size: 12px 12px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 4px;
  min-width: 12px;
  min-height: 12px;
}
.pool-info-bubble-adjustment {
  margin-left:14px!important;
}
.pool-tooltip1 {
  position: absolute;
  display: inline-block;
  padding: 7px;
  background-color: #ffffff;
  border-radius: 3px;
  white-space: normal;
  z-index: 1000;
  border: 1px solid #273746;
  margin-left: 5px;
  line-height: normal;
}