.warranty-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 8px;
}
.warranty-item{
    line-height: 30px;
}
.warranty-select {
    width: 90px;
    margin-top: 8px;
 }
 .warranty-input {
    width: 82px;
    margin-top: 8px;
 }
.warranty-input-label {
    display: inline-block;
    width: 245px;
  }
.warranty-fields {
    margin-left: 20px;
}
.warranty-textbox {
    display: inline-block;
    position: relative;
  }