.nf-container {
  margin: 0;
  position: fixed;
  bottom: 8%;
  right: 10%;
  width: 80%;
  z-index: 1000;
}
.nf-body {
  display: table;
  table-layout: fixed;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 2px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.38);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.38);
}
.nf-tr {
  display: table-row;
}
.nf-left {
  display: table-cell;
  width: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.nf-right {
  display: table-cell;
  width: 100%;
}
/*----------- Warning ----------------- */
.nf-warning-tab {
  background-color: #e6a015;
  border: 1px solid #e6a015;
  background-image: url('../images/warning.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 5px 2px;
}
.nf-warning-bar {
  background-color: #ded0a1;
  border: 1px solid #ded0a1;
  border-top-right-radius: 5px;
  padding: 2px;
  padding-left: 5px;
  border-bottom: 1px solid #ccbc87;
  font-weight: 600;
  color: #886800;
}
.nf-warning-content {
  background-color: #dfd6ae;
  border: 1px solid #dfd6ae;
  border-bottom-right-radius: 5px;
  padding: 10px;
  padding-left: 5px;
  color: #886800;
}

/*----------- Error ----------------- */
.nf-error-tab {
  background-color: #ca4747;
  border: 1px solid #dd5959;
  background-image: url('../images/error.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 5px 7px;
}
.nf-error-bar {
  background-color: #eebaba;
  border: 1px solid #dfaeae;
  border-top-right-radius: 5px;
  padding: 2px;
  padding-left: 5px;
  border-bottom: 1px solid #ccbc87;
  font-weight: 600;
  color: #6e0000;
}
.nf-error-content {
  background-color: #fff0f0;
  border: 1px solid #fff0f0;
  border-bottom-right-radius: 5px;
  padding: 10px;
  padding-left: 5px;
  color: #880000;
}

.nf-x {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}
.nf-div {
  padding: 3px;
}
/*----------- Info ----------------- */
.nf-info-tab {
  background-color: #6aa6dff3;
  border: 1px solid #6aa6dff3;
  background-image: url('../images/info.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 5px 2px;
}
.nf-info-bar {
  background-color: #bbdefff3;
  border: 1px solid #bbdefff3;
  border-top-right-radius: 5px;
  padding: 2px;
  padding-left: 5px;
  border-bottom: 1px solid #6aa6dff3;
  font-weight: 600;
  color: #293f7cf3;
}
.nf-info-content {
  background-color: #ebf6fff3;
  border: 1px solid #ebf6fff3;
  border-bottom-right-radius: 5px;
  padding: 10px;
  padding-left: 5px;
  color: #293f7cf3;
}
