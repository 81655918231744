.editArrangement {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    height: 540px;
    width: 480px;
    background-color: white;
  }
  .temp {
      font-size: 9px;
  }
  .refrig-page {
    clear: both;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
  }
  .header {
    background-color: #0063c0;
    color: white;
    text-align: center;
    padding: 7px;
    margin-bottom: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .refrig-select-options {
    width: 485px;
    white-space: nowrap;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .refrig-image-text {
    text-align: center;
    color:#0063c0;
  }
  .refrig-create-btn {
    margin-top: 20px;
    padding: 3px;
    text-align: center;
    background-color: #6aa6dff3;
    border: 1px solid black;
    border-radius: 3px;
    width: 70px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
  }
  .refrig-create-btn:hover {
    background-color: #0063c0;
  }
  .refrig-cancel-btn {
    margin-top: 20px;
    padding: 3px;
    text-align: center;
    background-color: #d3d3d3;
    border: 1px solid black;
    border-radius: 3px;
    width: 70px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    margin-left: 100px;
  }
  .refrig-cancel-btn:hover {
    background-color: #a6acaf;
  }
  .refrig-img-container {
    display: inline-block;
    width: 180px;
    height: 115px;
    margin-left: 35px;
    margin-bottom: 16px;
    margin-top: 16px;
    opacity: 0.8;
    cursor: pointer;
  }
  .refrig-img-available {
    display: inline-block;
    width: 180px;
    height: 100px;
    opacity: 0.6;
    cursor: pointer;
  }
  .refrig-img-auto-container {
    display: inline-block;
    width: 180px;
    height: 100px;
    margin-left: 35px;
    margin-bottom: 2px;
    margin-top: 16px;
    cursor: pointer;
    font-weight: bold;
    border: 4px solid  lightgray; 
  }
  .refrig-img-auto-container-selected {
    display: inline-block;
    width: 180px;
    height: 100px;
    margin-left: 35px;
    margin-bottom: 2px;
    margin-top: 16px;
    cursor: pointer;
    border: 4px solid  #0063c0; 
    font-weight: bold;
  }
  .refrig-img-auto-container:hover {
    opacity: 1;
  }
  .refrig-img-auto-available {
    display: inline-block;
    width: 180px;
    height:90px;
    opacity: 0.8;
    text-align: center;
    cursor: pointer;
    margin-top:37px;
    color: #0063c0;
  }
  .refrig-img-selected {
    opacity: 1;
    border: 4px solid  #0063c0;
  }
  .refrig-img-not-selected {
    border: 4px solid lightgray;
  }
  .refrig-img-container:hover {
    opacity: 1;
  }
 .refrig-M1 {
    background-image: url('../images/Circuit_Arrangement_M1.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M2 {
    background-image: url('../images/Circuit_Arrangement_M2.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M3 {
    background-image: url('../images/Circuit_Arrangement_M3.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M4 {
    background-image: url('../images/Circuit_Arrangement_M4.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M5 {
    background-image: url('../images/Circuit_Arrangement_M5.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M6 {
    background-image: url('../images/Circuit_Arrangement_M6.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M7 {
    background-image: url('../images/Circuit_Arrangement_M7.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P1 {
    background-image: url('../images/Circuit_Arrangement_P1.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P2 {
    background-image: url('../images/Circuit_Arrangement_P2.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P3 {
    background-image: url('../images/Circuit_Arrangement_P3.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P4 {
    background-image: url('../images/Circuit_Arrangement_P4.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-S1 {
    background-image: url('../images/Circuit_Arrangement_S1.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-S2 {
    background-image: url('../images/Circuit_Arrangement_S2.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-S3 {
    background-image: url('../images/Circuit_Arrangement_S3.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P5 {
    background-image: url('../images/Circuit_Arrangement_P5.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M8 {
    background-image: url('../images/Circuit_Arrangement_M8.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-P6 {
    background-image: url('../images/Circuit_Arrangement_P6.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-M9 {
    background-image: url('../images/Circuit_Arrangement_M9.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-T1 {
    background-image: url('../images/ThreeCircuitSingleTunnelT_T1.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-F1 {
    background-image: url('../images/FourCircuitSingleTunnelEndParallel_F1.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-T2 {
    background-image: url('../images/ThreeCircuitSideBySide_T2.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-T3 {
    background-image: url('../images/ThreeCircuitSideBySide_T3.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-F2 {
    background-image: url('../images/FourCircuitSideBySide_F2.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-T4 {
    background-image: url('../images/ThreeCircuitOverUnder_T4.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .refrig-F3 {
    background-image: url('../images/FourCircuitOverUnder_F3.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_One_Exhaust {
    background-image: url('../images/WSHP_SideBySide_One_Exhaust.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Two_ExhaustSeries {
    background-image: url('../images/WSHP_SideBySide_Two_ExhaustSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Three_ExhaustSeries {
    background-image: url('../images/WSHP_SideBySide_Three_ExhaustSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Four_ExhaustSeries {
    background-image: url('../images/WSHP_SideBySide_Four_ExhaustSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_One_End {
    background-image: url('../images/WSHP_SideBySide_One_End.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_One_EndTurned {
    background-image: url('../images/WSHP_SideBySide_One_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Two_EndSeries {
    background-image: url('../images/WSHP_SideBySide_Two_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Two_EndTurned {
    background-image: url('../images/WSHP_SideBySide_Two_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Three_EndSeries {
    background-image: url('../images/WSHP_SideBySide_Three_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Three_EndParallelSeries {
    background-image: url('../images/WSHP_SideBySide_Three_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Four_EndSeries {
    background-image: url('../images/WSHP_SideBySide_Four_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SideBySide_Four_EndParallelSeries {
    background-image: url('../images/WSHP_SideBySide_Four_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_One_End {
    background-image: url('../images/WSHP_Stacked_One_End.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_One_EndTurned {
    background-image: url('../images/WSHP_Stacked_One_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Two_EndSeries {
    background-image: url('../images/WSHP_Stacked_Two_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Two_EndTurned {
    background-image: url('../images/WSHP_Stacked_Two_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Three_EndSeries {
    background-image: url('../images/WSHP_Stacked_Three_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Three_EndParallelSeries {
    background-image: url('../images/WSHP_Stacked_Three_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Four_EndSeries {
    background-image: url('../images/WSHP_Stacked_Four_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_Stacked_Four_EndParallelSeries {
    background-image: url('../images/WSHP_Stacked_Four_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_One_End {
    background-image: url('../images/WSHP_SingleTunnel_One_End.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_One_EndTurned {
    background-image: url('../images/WSHP_SingleTunnel_One_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Two_EndSeries {
    background-image: url('../images/WSHP_SingleTunnel_Two_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Two_EndTurned {
    background-image: url('../images/WSHP_SingleTunnel_Two_EndTurned.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Three_EndSeries {
    background-image: url('../images/WSHP_SingleTunnel_Three_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Three_EndParallelSeries {
    background-image: url('../images/WSHP_SingleTunnel_Three_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Four_EndSeries {
    background-image: url('../images/WSHP_SingleTunnel_Four_EndSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }
  .WSHP_SingleTunnel_Four_EndParallelSeries {
    background-image: url('../images/WSHP_SingleTunnel_Four_EndParallelSeries.png');
    background-size: 180px 100px;
    background-repeat:no-repeat;
  }