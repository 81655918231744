.login-inputs {
  position: absolute;
  top: 70px;
  margin-left: 50px;
}
.login-title {
  font-size: 24px;
  font-weight: 600;
}
.login-label {
  font-size: 16px;
  margin-right: 8px;
  margin-top: 20px;
}
.login-input {
  text-align: left;
  margin-top: 5px;
  padding: 5px;
  width: 170px;
}
.login-btn {
  height: 34px;
  width: 90px;
  line-height: 34px;
  font-size: 15px;
  color: white;
  font-weight: 500;
  text-align: center;
  background-color: #4b95d6;
  border-radius: 5px;
  border: 1px solid #2870ad;
  cursor: pointer;
}
.login-btn:hover {
  background-color: #1e4d75;
}
.login-error {
  font-size: 12px;
  color: red;
  padding-top: 5px;
}
.login-top-spacer {
  height: 85px;
  width: 100%;
}
.login-link {
  color: #1e4d75;
  cursor: pointer;
}
