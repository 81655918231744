$design-menu-primary: #5a678af3;
$design-menu-secondary: #e7eefff3;

.design-submenu {
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid #dedede;
  position: fixed;
  left: 2px;
  top: 86px;
  background-color: #f5f5f5;
  z-index: 1;
}
.design-menu-link {
  display: inline-block;
  color: #5b7f95;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  border-bottom: 0;
}
.design-menu-link:hover {
  position: relative;
  color: #5b7f95;
  background-color: #becdd6;
  border: 1px solid #becdd6;
  border-radius: 4px;
  padding-left: 24px;
  padding-right: 24px;
}
.design-menu-link:hover:after {
  content: '';
  position: absolute;
  display: inline-block;
  height: 32px;
  width: 32px;
  top: 5;
  right: -15px;
  background-color: #becdd6;
  border-top-right-radius: 5px;
  transform: scale(0.707) rotate(45deg);
  box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.item-selected,
.item-selected:hover {
  position: relative;
  color: white;
  // background-color: #5b7f95;
  background-color: $design-menu-primary;
  border: 1px solid #5b7f95;
  border-radius: 4px;
  padding-left: 25px;
  padding-right: 25px;
}
.item-selected:after,
.item-selected:hover:after {
  content: '';
  position: absolute;
  display: inline-block;
  height: 32px;
  width: 32px;
  top: 5;
  right: -15px;
  // background-color: #5b7f95;
  background-color: $design-menu-primary;
  border-top-right-radius: 5px;
  transform: scale(0.707) rotate(45deg);
  box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.design-spacer {
  height: 5px;
  width: 100%;
  background-color: #f5f5f5;
}
.design-next-btn {
  position: absolute;
  top: 4px;
  left: 925px;
  width: 75px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin-left:25px;
  // background-color: #5b7f95;
  background-color: $design-menu-primary;
  border-radius: 5px;
  border: 1px solid #787878;
  cursor: pointer;
}
.design-next-hide {
  display:none;
}
// .design-next-btn:hover {
//   color: #ffffff;
//   background-color: #5b7f95;
// }
