$proj-primary: #293f7cf3;
$proj-secondary: #e7eefff3;
$proj-alt: #7c86a1f3;

.proj-table1 {
  display: table;
  table-layout: fixed;
  width: 500px;
  border-collapse: collapse;
  background-color: #ffffff;
  white-space: normal;
  word-break: break-all;
}
.proj-th {
  display: table-cell;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: $proj-primary;
}
.proj-th-jobname {
  display: table-cell;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: $proj-primary;
  width:215px;
}
.proj-top:hover {
  background-color: $proj-secondary !important;
  cursor: pointer;
}
.proj-row {
  padding-top:10px;
  padding-bottom:10px;
  padding-left:10px;
  padding-right:10px;
}
.proj-group-top {
  padding-top:10px;
  padding-bottom:4px;
  padding-left:10px;
  padding-right:10px;
}
.proj-group-bottom {
  padding-top:4px;
  padding-bottom:6px;
  padding-left:10px;
  padding-right:10px;
  background-color: #d4ddf0f3;
}
.proj-group {
  border-right: 1px solid $proj-primary;
  border-left: 1px solid $proj-primary;
  border-bottom: 1px solid $proj-primary;
  width:497px;
}
.proj-single {
  border-right: 1px solid $proj-primary;
  border-left: 1px solid $proj-primary;
  border-bottom: 1px solid $proj-primary;
  width:497px;
}
.proj-grid-row1{
  display:grid;
  grid-template-columns: 230px 145px 100px;
  font-size:14px;
}
.proj-grid-row2{
  display:grid;
  grid-template-columns: 15px 50px 50px 60px 70px 50px 185px; 
  font-size:14px;
}
.proj-20 {
  width: 20px !important;
}
.proj-100 {
  width: 100px !important;
}
.proj-129 {
  width:129px !important;
}
.proj-107 {
  width: 107px !important;
}
.proj-doc {
  width: 50px !important;
  padding: 3px 4px !important;
  text-align: center !important;
  vertical-align: middle;
}
.proj-tr {
  display: table-row;
  border: 1px solid $proj-primary;
}
.proj-tr:hover {
  background-color: $proj-secondary !important;
}
.proj-tr-active {
  background-color: $proj-secondary !important;
}
.proj-tr-running {
  display: table-row;
  border: 1px solid $proj-primary;
  background-color: rgb(220, 255, 220);
}
.proj-td {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  width: 215px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
//======================================
.project-list {
  display: inline-block;
  margin-left: 10px;
  width: 550px;
}
.project-list-title {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.project-search-title {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
}
.proj-search-text {
  margin-left: 5px;
}
.proj-search-id {
  margin-top: 10px;
}
.proj-list-select {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
}
.project-list-add {
  float: right;
  color: $proj-primary;
  line-height: 30px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 49px;
}
.project-list-add2 {
  color: $proj-primary;
  line-height: 30px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 3px;
}
.proj-filtered-by {
  float: left;
  margin-top: 18px;
  font-size: 14px;
}
.proj-space {
  height: 5px;
}
.proj-detail-section {
  margin-top: 45px;
}
.project-details {
  display: inline-block;
  width: 700px;
  vertical-align: top;
  font-size: 14px;
  margin-top: 8px;
}
.proj-details1 {
  display: inline-block;
  width: 110px;
  padding: 2px;
}
.proj-details2 {
  display: inline-block;
  padding: 2px;
}
.proj-notes-label {
  padding: 2px;
}
.proj-notes {
  height: 70px;
  padding: 3px;
  border: 1px solid #dedede;
  overflow-y: scroll;
}
.proj-doc-spacer {
  clear: both;
  height: 5px;
}
.project-documents-link {
  display: inline-block;
  color: $proj-primary;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 16px;
  vertical-align: top;
  line-height: 30px;
  cursor: pointer;
}
.proj-pdf-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url('../images/pdf.png');
  background-size: 70%;
  background-repeat: no-repeat;
}
.proj-pdf-icon-sm {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url('../images/pdf3.png');
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 20px;
}
.proj-zip-icon-sm {
  display: inline-block;
  width: 25px;
  height: 30px;
  background-image: url('../images/zip.png');
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 1px;
  left: 20px;
}
.proj-word-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url('../images/word.png');
  background-size: 70%;
  background-repeat: no-repeat;
}
.proj-doc-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url('../images/doc.png');
  background-size: 80%;
  background-repeat: no-repeat;
}
.proj-search-btn {
  position: absolute;
  top: 4px;
  left: 270px;
  height: 23px;
  width: 40px;
  line-height: 25px;
  background-color: $proj-primary;
  border-radius: 5px;
  border: 1px solid $proj-primary;
  cursor: pointer;
}
.proj-search-id-text {
  color: darkred;
}
.proj-searchId-btn {
  position: absolute;
  top: 42px;
  left: 310px;
  height: 23px;
  width: 40px;
  line-height: 25px;
  background-color: $proj-primary;
  border-radius: 5px;
  border: 1px solid $proj-primary;
  cursor: pointer;
}
.proj-copyId-btn {
  position: absolute;
  top: 44px;
  left: 300px;
  height: 23px;
  width: 50px;
  text-align: center;
  line-height: 22px;
  background-color: $proj-primary;
  border-radius: 5px;
  border: 1px solid $proj-primary;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}
.proj-copy-error {
  margin-left: 120px;
  font-size: 12px;
  font-weight: 400;
  color: red;
}
// .proj-search-text {
//   color:white;
//   display:inline-block;
//   vertical-align: text-bottom;
// }
.proj-search-symbol {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: white;
  font-size: 26px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}
.proj-paging {
  display: inline-block;
  height: 20px;
  margin-left: 15px;
  padding: 3px 6px;
  color: $proj-alt;
  font-size: 14px;
}
.proj-paging-active {
  border: 1px solid $proj-alt;
  cursor:default!important;
}
.noPointerEvents {
  pointer-events: none;
}
.blue {
  color:blue;
  cursor: pointer;
}
.proj-paging-control {
  clear: both;
  margin-top: 10px;
  text-wrap:wrap;
}
.proj-selected-project {
  padding-top: 7px;
  margin-bottom:12px;
  font-size:16px;
}
// ------------------------------
.proj-unit-td {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  min-width: 30px;
  white-space: nowrap;
  overflow: hidden;
}
.proj-unit-td-name {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  max-width: 140px;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position:relative;
}
.proj-unit-tag{
  display:inline-block;
  padding:10px;
}
.proj-lock-icon{
  position:absolute;
  top:5px;
  left:2px;
  height:22px;
  width:22px;
  min-height:22px;
  min-width:22px;
  background-image: url('../images/lock-white.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
 }
 .proj-lock-spacer{
  display:inline-block;
  width:20px;
  min-width:20px;
 }
.proj-unit-td-model {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  max-width: 250px;
  min-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proj-unit-td-price {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  max-width: 90px;
  min-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proj-unit-td-qty {
  display: table-cell;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  max-width: 30px;
  min-width: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proj-unit-sub-heading-grid {
  display: grid;
  grid-template-columns: 160px 270px 90px 50px 130px;  //700
  padding:10px;
  font-weight: 500;
  background-color: $proj-primary;
  color: #ffffff;
}
.center-align-grid-cell {
  align-self: center;
}
.long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.proj-selected-heading-grid {
  display: grid;
  grid-template-columns: 310px 155px 120px 110px;
  margin-bottom:7px;
  margin-top:81px;
  font-weight: 500;
}
.proj-unit-table {
  display: table;
  border-collapse: collapse;
  border-top: 1px solid $proj-primary;
  border-left: 1px solid $proj-primary;
  border-right: 1px solid $proj-primary;
}
.proj-unit-tr {
  border-bottom: 1px solid $proj-primary;
  cursor: pointer;
}
.proj-unit-tr:hover {
  background-color: $proj-secondary;
}
.proj-unit-tr-purple{
  border-bottom: 1px solid $proj-primary;
  background-color: rgb(164, 71, 164);
  color:#ffffff;
  cursor: pointer;
}
.proj-unit-tr-purple:hover {
  background-color: rgb(129, 35, 129);
}

.proj-unit-field1 {
  display: inline-block;
  width: 60px;
  padding: 5px;
  margin-left: 10px;
}
.proj-unit-field2 {
  display: inline-block;
  padding: 5px;
}
.proj-copy-link {
  display: inline-block;
  margin-left: 10px;
}
.proj-link {
  color: #304169f3;
  cursor: pointer;
}
.proj-link-white {
  color: #ffffff;
  cursor: pointer;
}
.proj-link-gray {
  color: #b0b0b0;
  cursor: pointer;
}
.outputs-link-disabled {
  color: #717c97f3;
  cursor:default;
}
.proj-gear {
  float: right;
  margin-right: 3px;
  width: 20px;
  height: 25px;
  background-image: url('../images/cog-solid.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 1px 5px;
  cursor: pointer;
}
.proj-input {
  width: 280px;
}
.proj-btn {
  padding: 3px;
  text-align: center;
  background-color: $proj-primary;
  border: 1px solid $proj-primary;
  border-radius: 3px;
  width: 50px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
}
.proj-clear-search-btn {
  color: blue;
  display: inline-block;
  margin-left: 55px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 18px;
}
.proj-btn-edit {
  position: absolute;
  top: 10px;
  right: 58px;
}
.proj-btn-copy {
  display: inline-block;
  margin-left: 10px;
}
.proj-btn-add {
  display: inline-block;
  color: #ffffff;
  height: 15px;
  width: 100px;
  font-size: 14px;
  line-height: 15px;
  margin-left: 5px;
  display:inline-block;
}
//===================
.proj-arrow {
  content: '';
  border: solid #575757;
  border-width: 0 3px 3px 0;
  padding: 3px;
  position: absolute;
  right: 22px;
  top: 17px;
  cursor: pointer;
}
.proj-down-arrow {
  -ms-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
}
.proj-up-arrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  right: 20px;
}
//===================
.project-td1 {
  display: table-cell;
}
.project-td2 {
  display: table-cell;
  width: 495px;
}
.project-label {
  display: inline-block;
  font-size: 16px;
  margin-right: 8px;
}
.project-id {
  display: inline-block;
  text-align: left;
  margin-top: 8px;
  height: 32px;
  width: 170px;
}
.project-error {
  border: 1px solid red;
}
.project-new-btn {
  display: inline-block;
  height: 34px;
  width: 90px;
  line-height: 34px;
  font-size: 15px;
  color: #022108;
  font-weight: 500;
  text-align: center;
  background-color: #a7fc98;
  border-radius: 5px;
  border: 1px solid green;
  margin-left: 8px;
  cursor: pointer;
}
.project-delete-btn {
  display: inline-block;
  height: 34px;
  width: 120px;
  line-height: 34px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dedede;
  margin-left: 8px;
  cursor: pointer;
}
.project-top-spacer {
  height: 60px;
  width: 100%;
}
.project-content {
  position: relative;
  z-index: 0;
}
.project-validation {
  margin-left: 78px;
  margin-top: 3px;
  font-size: 11px;
  color: red;
}
.project-delete-success {
  margin-left: 260px;
  margin-top: 5px;
  font-size: 11px;
  color: green;
}
.project-delete-fail {
  margin-left: 200px;
  margin-top: 5px;
  font-size: 11px;
  color: red;
}
.proj-no-results {
  padding: 20px 5px 75px 20px;
}
.project-mgmt-main {
  white-space: nowrap;
}
.proj-modal-label {
  font-size: 14px;
  margin-right: 15px;
  vertical-align: bottom;
  display: inline-block;
  width:130px;
}
.project-loading{
  display:inline-block;
  background-image: url('../images/spinner.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
  background-repeat: no-repeat;
  margin-right:5px;
}
.proj-transfer{
  padding:15px;
  color:red;
}
.proj-progress-bar {
  display: inline-block;
  width: 40px;
  height: 10px;
  background-image: url('../images/progress.gif');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom 2px left;
  margin-right: 2px;
}
.proj-progress {
  display: inline-block;
}
.proj-status-failed {
  color: red;
}
.white {
  color:white;
}
.yellow {
  color: lime;
}
.project-jobname-arrow-up{
  position: absolute;
  left: 88px;
  top: 255px;
  cursor:pointer;
}
.project-jobname-arrow-down{
  position: absolute;
  left: 88px;
  top: 266px;
  cursor: pointer;
}
.project-lastmodified-arrow-up{
  position: absolute;
  left: 486px;
  top: 255px;
  cursor:pointer;
}
.project-lastmodified-arrow-down{
  position: absolute;
  left: 486px;
  top: 266px;
  cursor: pointer;
}
.project-status-dropdown:hover {
  cursor: pointer;
}
.project-status-dropdown {
  position: absolute;
  left: 348px;
  top: 260px;
}
.project-hide {
  display: none!important;
}
.project-status-select-control {
  position: absolute;
  left: 250px;
  top: 258px;
  width:90px;
  cursor: pointer;
  z-index: 500;
}
.copyJob-placeholder{
  height:40px;
  min-height:40px;
  width:500px;
  min-width:500px;
}