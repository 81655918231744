.s-error-container {
  margin: 0;
  position: fixed;
  top: 80%;
  left: 50%;
  height: 100px;
  width: 80%;
  padding: 15px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 165, 165, 0.9);
  border: 1px solid #ff7676;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.s-error-content {
  display: inline-block;
  margin-left: 15px;
  line-height: 20px;
}
.s-error-icon {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  background-color: red;
}
.s-error-close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 22px;
  color: #d44747;
  padding: 1px 6px;
  border: 1px solid #d44747;
  cursor: pointer;
}
