.app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #f5f5f5;
}
.app-fixed-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #d9d9d6;
  z-index: 10;
}
.app-header-table {
  display: table;
  width: 100%;
  height: 45px;
  background-color: #d9d9d6;
}
.app-header-td1 {
  display: table-cell;
  width: 150px;
  // uncomment below to add back an image.
  // display: table-cell;
  // width: 194px;
  // background-image: url(../images/fbird.png);
  // background-size: 42px 40px;
  // background-repeat: no-repeat;
  // background-position: 3px 3px;
  // padding-left: 40px;
}
.app-header-td2 {
  display: table-cell;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  min-width: 325px;
  width: 500px;
}
.app-header-td3 {
  display: table-cell;
  margin: 0;
  vertical-align: middle;
  width: 300px;
  white-space: nowrap;
}
.app-header-td4 {
  display: table-cell;
  margin: 0;
  vertical-align: middle;
  width: 900px;
}

.app-chevron-bar {
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  z-index: 1;
  // background-color: #43a7c8;
  background-color: #6aa6dff3;
}
.app-logo-img {
  height: 35px;
  padding-top: 7px;
  padding-left: 12px;
}
.app-body {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  // background-color: #f5f5f5;
  background-color: #f9fbff;
}
.app-body-top {
  display: table-row;
  height: 130px;
  width: 100%;
}
.app-body-content {
  display: table-row;
  width: 100%;
}
.app-body-bottom {
  display: table-row;
  height: 45px;
  width: 100%;
}
.app-bottom-spacer {
  width: 100%;
}
.app-left-td {
  display: table-cell;
  width: 121px;
  height: 100%;
}
.app-left-spacer {
  display: block;
  width: 121px;
}
.app-right-td {
  display: table-cell;
}
.app-pricing {
  padding-left: 15px;
  font-size: 18px;
  font-weight: 600;
}
.app-outputs {
  padding-left: 15px;
}
.app-outputs-title {
  font-size: 20px;
  font-weight: 600;
}
.app-display-none {
  display: none;
}
.app-visibility-hidden {
  visibility: hidden;
}
.app-footer {
  height: 40px;
  width: 100%;
  background-color: lightgray;
  position: fixed;
  bottom: 0;
}
.app-footer-content {
  font-size: 13px;
  margin-left: 10px;
  margin-top: 12px;
  font-weight: 500;
}
.App-link {
  color: #09d3ac;
}
.spinner {
  background-image: url('../images/spinner.svg');
  background-size: 13px 13px;
  height: 13px;
  width: 13px;
  background-repeat: no-repeat;
  margin-left:12px;
}
.app-loading {
  position: fixed;
  background-image: url('../images/loading2.svg');
  background-size: 180px 180px;
  height: 180px;
  width: 180px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 600;
}
.app-loading-blocker {
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.5 !important;
  background-color: #434343;
  height: 100%;
  width: 100%;
  z-index: 500;
}
.app-required {
  font-size: 14px;
  color: red;
  // float: right;
}
.app-required-label {
  font-size: 14px;
  color: red;
  float: right;
  margin-right: 28px;
}
.app-clear {
  clear: both;
}
