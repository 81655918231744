.system-inputs-main {
  margin-left: 20px;
  margin-top: 10px;
  white-space: nowrap;
}
.system-input-container {
  width: 520px;
  display: inline-block;
  vertical-align: top;
}
.system-input-container2 {
  display: inline-block;
  vertical-align: top;
  width: 440px;
}
.system-addl-components {
  width: 455px;
  padding-left: 10px;
  padding-bottom: 20px;
}
.system-fields-left {
  margin-left: 10px;
}
.system-input-label {
  display: inline-block;
  width: 215px;
  text-wrap:wrap;
}
.system-label-sm {
  font-size: 11px;
  margin-top: 10px;
}
.system-heading {
  margin-top: 20px;
  font-weight: bold;
  display: inline-block;
}
.system-next-btn {
  display: inline-block;
  margin-top: 15px;
  min-width: 245px;
  text-align: right;
}
.oaConditionsLabel {
  margin-top:5px;
}