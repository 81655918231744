.unit-page {
  clear: both;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.unit-row {
  margin-left: 3px;
}
.unit-row:after {
  content: '';
  display: table;
  clear: both;
}
.unit-item {
  float: left;
  width: 48%;
  margin-left: 1px;
  margin-bottom: 2px;
  cursor: pointer;
  text-align: center;
  height: 185px;
  border: 1px solid #dedede;
  border-radius: 4px;
}
.unit-icon1 {
  margin-top: 20px;
  width: 150px;
}
.unit-icon2 {
  margin-top: 20px;
  width: 220px;
}
.unit-label {
  margin-top: 15px;
  font-weight: bold;
}
.unit-top-spacer {
  height: 105px;
  width: 100%;
}
.unit-select-options {
  // position: absolute;
  // top: 100px;
  width: 860px;
  white-space: nowrap;
}
.unit-img-box {
  display: table-cell;
  width: 40%;
  text-align: center;
  border-right: 1px solid lightgray;
}
.unit-img-available {
  display: inline-block;
  width: 385px;
  height: 165px;
  margin-left: 35px;
  margin-bottom: 16px;
  margin-top: 16px;
  opacity: 0.6;
  cursor: pointer;
}
.unit-img-available:hover {
  opacity: 1;
}
.unit-img-unavailable {
  display: inline-block;
  width: 385px;
  height: 165px;
  margin-left: 35px;
  margin-bottom: 16px;
  margin-top: 16px;
  opacity: 0.6;
}
.unit-img-selected {
  opacity: 1;
  border: 4px solid rgb(255, 217, 0);
  margin-bottom: 12px;
}
.unit-spacer {
  width: 100%;
  height: 100px;
}
.unit-img-eru {
  background-image: url('../images/Energy_Recovery_Button_7692.png');
  background-size: 385px 165px;
}
.unit-img-ahu {
  background-image: url('../images/Single Tunnel_Graphic_Button_7692.png');
  background-size: 385px 165px;
}
.unit-img-pool {
  background-image: url('../images/Pools_Button_7692.png');
  background-size: 385px 165px;
}
.pointer-events-none {
  pointer-events: none;
}
.unit-img-dehum {
  background-image: url('../images/Desiccant_Button_7692.png');
  background-size: 385px 165px;
}
.unit-img-laser1 {
  background-image: url('../images/Laser_1_Button_7692.png');
  background-size: 385px 165px;
}
.unit-img-laser2 {
  background-image: url('../images/Laser_2_Button_7692.png');
  background-size: 385px 165px;
}
