.perf-main {
  display: table;
  height: 100%;
  width: 97%;
  padding-top: 15px;
}
.perf-column {
  display: table-cell;
  padding: 5px;
  text-align: center;
}
.perf-column1 {
  display: table-cell;
  width: 20px;
  min-width: 20px;
}
.perf-column2 {
  display: table-cell;
  padding: 5px;
  text-align: center;
}
.perf-column3 {
  display: table-cell;
  position: relative;
  width: 565px;
  vertical-align: top;
}
.perf-customizations {
  height: 100%;
  overflow-y: scroll;
}
.perf-col-content {
  position: relative;
  border: 1px solid lightgray;
  border-radius: 10px;
  height: 300px;
  padding: 5px;
}
.perf-col-content-active {
  position: relative;
  background-color: #e3ffe8;
  border: 1px solid #1e7b30;
  border-radius: 10px;
  height: 300px;
  box-shadow: 3px 3px 3px 3px #ccc;
  padding: 5px;
}
.perf-col-content-warning {
  position: relative;
  background-color: #ffe3e3;
  border: 1px solid #7b1e1e;
  border-radius: 10px;
  height: 350px;
  box-shadow: 3px 3px 3px 3px #ccc;
  padding: 5px;
}

.perf-heading {
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
}
.perf-heading-disabled {
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
  color: #7e7e7e;
}
.perf-heading2 {
  text-align: center;
  font-weight: 600;
  width: 540px;
}
.perf-heading3 {
  text-align: center;
  font-weight: 600;
  width: 540px;
  margin-top: 20px;
}
.perf-next-btn {
  position: absolute;
  top: -10px;
  right: 10px;
}
.perf-row {
  white-space: nowrap;
}
.perf-fields1 {
  margin-top: 30px;
  height: 110px;
  text-align: center;
}
.perf-fields2 {
  margin-left: 20px;
  margin-top: 25px;
  text-align: left;
}
.perf-fields3 {
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  text-align: left;
}
.perf-fields4 {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.perf-fields5 {
  margin-top: 6px;
  height: 80px;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: top;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.perf-radio {
  display: inline-block;
  width: 25px;
}
.perf-radio-label {
  display: inline-block;
  width: 145px;
  text-align: left;
  line-height: 26px;
}
.perf-label {
  line-height: 26px;
}

.perf-label1 {
  display: inline-block;
  width: 140px;
  line-height: 26px;
}
.perf-label1-disabled {
  display: inline-block;
  width: 140px;
  line-height: 26px;
  color: #c4c4c4 !important;
}
.perf-label2 {
  display: inline-block;
  width: 80px;
  text-align: right;
  line-height: 26px;
  padding-right: 3px;
}
.perf-label2-disabled {
  display: inline-block;
  width: 80px;
  text-align: right;
  line-height: 26px;
  padding-right: 3px;
  color: #c4c4c4 !important;
}

.perf-warninglabel1 {
  display: inline-block;
  width: 140px;
  line-height: 26px;
  color: red;
}
.perf-warninglabel1-disabled {
  display: inline-block;
  width: 140px;
  line-height: 26px;
  color: #c4c4c4 !important;
}
.perf-warninglabel2 {
  display: inline-block;
  width: 80px;
  text-align: right;
  line-height: 26px;
  padding-right: 3px;
  color: red;
}
.perf-warninglabel2-disabled {
  display: inline-block;
  width: 80px;
  text-align: right;
  line-height: 26px;
  padding-right: 3px;
  color: #c4c4c4 !important;
}
.perf-reset-btn-small{
  width: 110px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  line-height: 25px;
  background-color: #a7fc98;
  border: 2px solid green;
  border-radius: 3px;
  font-size: 14px;
  color: #022108;
  text-align: center;
  font-weight: 500;
  cursor: pointer;  
}
.perf-reset-btn-small-disabled{
  width: 110px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  line-height: 25px;
  background-color: #d2d2d2;
  border: 2px solid #b0b0b0;
  border-radius: 3px;
  font-size: 14px;
  color: #707070;
  text-align: center;
  font-weight: 500;
  cursor: default;
  pointer-events: none;
}
.perf-reset-btn {
  width: 192px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  line-height: 30px;
  background-color: #a7fc98;
  border: 4px solid green;
  border-radius: 3px;
  font-size: 14px;
  color: #022108;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}
.perf-reset-btn-disabled {
  width: 192px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  line-height: 30px;
  background-color: #d2d2d2;
  border: 4px solid #b0b0b0;
  border-radius: 3px;
  font-size: 14px;
  color: #707070;
  text-align: center;
  font-weight: 500;
  cursor: default;
  pointer-events: none;
}
.perf-accordian-container {
  margin-top: 6px;
  padding: 5px;
}
.perf-left-cols {
  display: table-cell;
}
.perf-left-cols-table {
  display: table;
  width: 100%;
}
.perf-fpm {
  width: 270px;
  margin-left: auto;
  margin-right: auto;
}
.perf-clear {
  clear: both;
}
.perf-design-btn {
  // position: absolute;
  // width: 100%;
  // bottom: 70px;
}
.perf-clear-limits {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  color: #006aac;
  cursor: pointer;
}
.perf-input-label {
  display: inline-block;
  width: 175px;
  margin-top: 5px;
}
.perf-dropdown-sm {
  width: 48px;
  margin-left: 10px;
  padding: 1px 2px;
}
.perf-spacer {
  height: 15px;
}
