.newProject {
    border-radius:10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    height:260px;
    width:345px;
    background-color: white;
}
.header {
    background-color: #293f7cf3;
    color:white;
    text-align: center;
    padding:7px;
    margin-bottom:15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.projectName {
    margin-left: 10px;
}
.projectInput{
  display: inline-block;
  margin-left:2px;
  box-sizing: border-box;
  border-style: solid;
  border-width:1px;
}
.projectInputDropdown {
  display: inline-block;
  margin-left:2px;
  box-sizing: border-box;
  border-left-style: solid;
  border-left-width:1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.InputRow{
  height: 25px;
  margin-left:10px;
}
.proj-create-btn {
    margin-top:20px;
    padding: 3px;
    text-align: center;
    background-color: #6aa6dff3;
    border: 1px solid black;
    border-radius: 3px;
    width: 70px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    display:inline-block;
  }
  .proj-create-btn:hover {
    background-color: #1e4d75;
  }
  .proj-cancel-btn {
    margin-top:20px;
    padding: 3px;
    text-align: center;
    background-color: #d3d3d3;
    border: 1px solid  black;;
    border-radius: 3px;
    width: 70px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    display:inline-block;
    margin-left:100px;
  }
  .proj-cancel-btn:hover {
    background-color: #a6acaf;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }