.drawing-inputs-main {
  margin-left: 5px;
  white-space: nowrap;
}
.drawing-container {
  display: table;
  width: 98%;
}
.drawing-right {
  display: table-cell;
  position: relative;
  width: auto;
  overflow: hidden;
  margin-top: 10px;
  vertical-align: top;
}
.drawing-left {
  display: table-cell;
  width: 400px;
  vertical-align: top;
}
.drawing-td3 {
  display: table-cell;
  width: 100%;
}
.drawing-fields-left {
  margin-left: 10px;
  width: 400px;
}
.drawing-input-label {
  display: inline-block;
  width: 135px;
}
.drawing-input {
  display: inline-block;
}
.drawing-drawing-grid {
  position: relative;
  vertical-align: middle;
  border: 2px solid #273746;
  background-color: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
}
.drawing-next-img {
  height: 40px;
  cursor: pointer;
}
.drawing-dimensions {
  margin-top: 15px;
}
.dimensions-label {
  position: relative;
  display: block;
  margin-left: 5px;
  padding: 3px;
  width: 250px;
}
.dimensions-info {
  position: absolute;
  top: 0;
  height: 12px;
  width: 12px;
  background-image: url('../images/info.png');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 8px;
}
.dimensions-tooltip1 {
  position: absolute;
  top: 0;
  left: 170px;
  padding: 7px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 3px;
  white-space: normal;
  z-index: 1000;
  border: 1px solid #273746;
}
.dimensions-tooltip-content {
  font-size: 13px;
  width: 400px;
}
.dimensions-li {
  list-style-position: outside;
}
.drawing-stats {
  display: inline-block;
  width: 250px;
}
.drawing-heading {
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
  margin-top: 10px;
}
.drawing-btn-label {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}
.refrig-location {
  font-size: 15px;
}
.edit-link {
  margin-left: 10px;
}
.drawing-btn {
  display: inline-block;
  width: 90px;
  height: 25px;
  line-height: 25px;
  margin-left: 3px;
  font-size: 15px;
  color: white;
  font-weight: 400;
  text-align: center;
  background-color: #43a7c8;
  border-radius: 5px;
  border: 1px solid #3089a7;
  cursor: pointer;
}
.drawing-btn:hover {
  background-color: #90b4c9;
}
.drawing-btn-135 {
  width: 135px !important;
}
.drawing-btn-selected,
.drawing-btn-selected:hover {
  background-color: #5b7f95;
}
.drawing-svg {
  text-align: center;
}
/*----------- drawing screens ----------- */
.drawing-fieldset {
  width: 300px;
  border-radius: 5px;
  margin: 0;
  padding: 5px;
}
.drawing-fieldset-layout-adjustment {
  width: 350px;
  border-radius: 5px;
  margin: 0;
  padding: 5px;
}
.drawing-legend {
  font-size: 16px;
  font-weight: 600;
}
.doors-fieldset {
  border-color: #00c000;
}
.doors-legend {
  color: #00c000;
}
.cwcoil-fieldset {
  border-color: #0063c0;
}
.wshp-dxcoil-fieldset {
  border-color:purple;
}
.cwcoil-legend {
  color: #0063c0;
}
.wshp-dxcoil{
  color:purple;
}
.hwcoil-fieldset {
  border-color: #c00000;
}
.hwcoil-legend {
  color: #c00000;
}
.elect-fieldset {
  border-color: #ff9100;
}
.elect-legend {
  color: #ff9100;
}
.furnace-fieldset {
  border-color: #c00000;
}
.refrigeration-fieldset {
  border-color: #0063c0;
}
.unit-dimensions-fieldset {
  border-color: darkgray;
}
.unit-dimension-legend {
  color:darkgray;
}
.refrigeration-legend {
  color: #0063c0;
}
.furnace-legend {
  color: #c00000;
}
.length-fieldset {
  border-color: #000000;
}
.length-legend {
  color: #000000;
}
.legend-spacer {
  margin-top: 30px;
}
.drawing-fields-spacer {
  height: 15px;
}
.drawing-input-label {
  display: inline-block;
  width: 175px;
  font-size: 13px;
}
.drawing-input-label2 {
  display: inline-block;
  width: 225px;
  font-size: 13px;
}
.drawing-input-label3 {
  display: inline-block;
  font-size: 14px;
}
.drawing-select {
  width: 45px;
  font-size: 14px;
}
.drawing-clearances {
  margin-top: 10px;
  width: 300px;
  padding: 5px;
  white-space: normal;
}
.drawing-tr {
  display: table-row;
}
.drawing-li {
  display: table-cell;
}
.drawing-text {
  display: table-cell;
  padding: 3px;
}
.drawing-update-design-margin {
  margin-left:0px;
}
