$proj-primary: #293f7cf3;
$proj-secondary: #e7eefff3;
$proj-alt: #7c86a1f3;
.copyUnit {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 350px;
  background-color: white;
}
.header {
  background-color: #293f7cf3;
  color: white;
  text-align: center;
  padding: 7px;
  margin-bottom: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.unitName {
  text-align: center;
}
.unitTagInput {
  width: 230px;
}
.unitCopyLegend {
  font-size: 14px;
  margin-bottom: 3px;
  margin-top: 20px;
  margin-left: 11px;
}
.divChoices {
  display: inline-block;
}
.unitModalLabel {
  font-size: 14px;
  margin-right: 10px;
  vertical-align: bottom;
}
.projectChoices {
  display: inline-block;
  border-radius: 5px;
  border-width: 1px;
  border-color: $proj-primary;
  border-style: solid;
  padding: 12px;
  text-align: left;
  width: 300px;
  margin-left: 11px;
  font-size: 14px;
  overflow-y: auto;
  height: 400px;
}
.proj-create-btn {
  margin-top: 20px;
  padding: 3px;
  text-align: center;
  background-color: #6aa6dff3;
  border: 1px solid black;
  border-radius: 3px;
  width: 70px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}
.proj-create-btn:hover {
  background-color: #1e4d75;
}
.proj-cancel-btn {
  margin-top: 20px;
  padding: 3px;
  text-align: center;
  background-color: #d3d3d3;
  border: 1px solid black;
  border-radius: 3px;
  width: 70px;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  margin-left: 100px;
}
.proj-cancel-btn:hover {
  background-color: #a6acaf;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
