
$topmenu-inactive: #6aa6dff3;
$topmenu-active: #293f7cf3;

.topmenu {
    width: 100%;
    height:40px;
    white-space: nowrap;
    position: fixed;
    left: 0;
    top: 46px;
    background-color: $topmenu-inactive;
    z-index: 1;
  }
  .topmenu-link {
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    height: 40px;
    line-height:40px;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 0;
  }
.topmenu-selected,
.topmenu-selected:hover {
  position: relative;
  color: white;
  background-color: $topmenu-active;
  padding-left: 25px;
  padding-right: 25px;
}
.topmenu-arrow{
    display: inline-block;
    height:40px;
    width:21px;
    background-size: 21px 40px;
    background-repeat: no-repeat;
    vertical-align: top;
    margin-left: 0;
    margin-top:0;
    background-image: url(../images/ltbluearrow-tip.png);
}
.topmenu-arrow-selected{
    display: inline-block;
    height:40px;
    width:21px;
    background-size: 21px 40px;
    background-repeat: no-repeat;
    vertical-align: top;
    margin-left: 0;
    margin-top:0;
    background-image: url(../images/dkbluearrow-tip.png);
}
.topmenu-tail-selected{
    display: inline-block;
    height:40px;
    width:21px;
    background-size: 21px 40px;
    background-repeat: no-repeat;
    vertical-align: top;
    margin-left: 0;
    margin-top:0;
    background-image: url(../images/dkbluearrow-tail.png);
}